import React from "react";
interface NotFound {
  msg: string;
}
const Notfound: React.FC<NotFound> = (prop) => {
  return (
    <>
      <tr className="border-b text-sm rounded-md text-center w-full">
        <td colSpan={12} className="text-center py-4">
          {prop.msg}
        </td>
      </tr>
    </>
  );
};

export default Notfound;
