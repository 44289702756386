import { AiOutlineDelete } from "react-icons/ai";
import React from "react";
import ListComp from "../../../components/ListComp";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import { CiEdit } from "react-icons/ci";
import { variant_lists } from "../../../../api/websocket/payload";
import Notfound from "../../../components/Notfound";

interface IList {
  edit: Function;
  toggleWarn: Function;
  onEdit:Function,
 }

const List: React.FunctionComponent<IList> = (props) => {
  const { variant_list } = usePosterReducers();
 
  
  const header = [ "Name","Quantity","Product Limit","Price","Created At","Active","Actions"];
  return (
    <ListComp pages={variant_list?.pagination} payLoad={variant_lists} header={header}>
      
        {variant_list?.data?.length > 0 ?
          variant_list?.data?.map((item, index) => (
            
              <tr key={index}  className="border-b text-sm rounded-md  ">
                <td className="p-3">{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.product_limit}</td>
                <td>{item.price?Number(item.price)/100:'-'}</td>
                <td>{dateFormat(item.created_at)}</td>
                <td className="px-4  text-sm py-2">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={item.is_active}
                      onChange={()=>{}}
                      onClick={(e) => props.toggleWarn(e,item,'edit')}
                     
                    />
                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </td>
                <td>
                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={() => props.onEdit(item,'edit')}
                      className="p-2 text-base text-black rounded-md"
                    >
                      <CiEdit />
                    </button>
                    <button
                      onClick={() => props.toggleWarn("",item.id,'delete')}
                      className="p-2  text-base text-black rounded-md"
                    >
                      <AiOutlineDelete />
                    </button>
                  </div>
                </td>
              </tr>
            
          )):
          <Notfound msg="Variant Not Found!"/>
          }
  
    </ListComp>
  );
};

export default List;
