/* eslint-disable */
import { IoEyeOutline } from "react-icons/io5";
import React from "react";
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import ListComp from "../../components/ListComp";
import { dateFormat } from "../../../utils/common";
import { edit_order_status, order_lists } from "../../../api/websocket/payload";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/dropdown";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import Notfound from "../../components/Notfound";

interface IList {}

const List: React.FunctionComponent<IList> = (props) => {
  const { order_list } = usePosterReducers();
  const navigate = useNavigate();
  const { send } = useWebSocket();
  const statusOptions = [
    { value: "confirmed", label: "Confirmed" },
    { value: "processing", label: "Processing" },
    { value: "delivered", label: "Delivered" },
  ];
  const header = [
    "username",
    "email",
    "order type",
    "coupon status",
    "gift card status",
    "Paid amount ($)",
    "total amount ($)",
    "payment status",
    "Has Subscription",
    "Created At",
    "Actions",
  ];

  function updateOrderStatus(e: string, order_id: string) {
    edit_order_status.request.order_id = order_id;
    edit_order_status.request.status = e;
    send(edit_order_status);
  }

  return (
    <ListComp
      payLoad={order_lists}
      pages={order_list?.pagination}
      header={header}
    >
      {order_list?.data?.length > 0 ? (
        order_list?.data?.map((item, index) => {
          return (
            <tr key={index} className="border-b text-sm rounded-md  ">
              <td className="py-3">{item.details?.customer_info?.name}</td>
              <td className="py-3">{item.details?.customer_info?.email}</td>
              <td>{item.order_type}</td>
              <td>{item.promo_coupon_applied ? "Applied" : "Not Applied"}</td>
              <td>{item.gift_card_applied ? "Applied" : "Not Applied"}</td>
              <td>{Number(item.final_amount) / 100}</td>
              <td>{Number(item.total_amount) / 100}</td>
              <td>
                {/* {item.is_paid ? "Paid" : "Unpaid"} */}
                <span
                  className={
                    item.is_paid
                      ? "bg-green-100 text-green-600 px-2 py-1 rounded"
                      : "bg-red-100 text-red-600 px-2 py-1 rounded"
                  }
                >
                  {item.is_paid ? "Paid" : "Unpaid"}
                </span>
                </td>
              <td>
                {/* {item.product_subscription_id !== null ? "Yes" : "No"} */}
                <span
                  className={
                    item.product_subscription_id
                      ? "bg-green-100 text-green-600 px-2 py-1 rounded"
                      : "bg-red-100 text-red-600 px-2 py-1 rounded"
                  }
                >
                  {item.product_subscription_id ? "Yes" : "No"}
                </span>

              </td>
              <td>{dateFormat(item.created_at)}</td>
              <td>
                <button
                  onClick={() => navigate("/order-management/" + item.id)}
                  className="p-2   text-black rounded-md"
                >
                  <IoEyeOutline />
                </button>
              </td>
            </tr>
          );
        })
      ) : (
        <Notfound msg="Orders Not Found!"/>
      )}
    </ListComp>
  );
};

export default List;
