import React from 'react'
interface IPageLayout{
    children:React.ReactNode
    className?:boolean
}
const PageLayout:React.FC<IPageLayout> = (prop) => {
  return (
    <div className={`w-full h-full  bg-white rounded-md  ${prop?.className? "overflow-auto": "overflow-hidden"}`}>
        {prop.children}
    </div>
  )
}

export default PageLayout