import "./App.css";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./app/view/pages/dashboard";

import Login from "./app/view/pages/login";
import Sidebar from "./app/view/components/Sidebar";
import Header from "./app/view/components/Header";
import Category from "./app/view/pages/productManagement/catagory";
import Product from "./app/view/pages/productManagement/product";
import Variant from "./app/view/pages/productManagement/variant";
import Setting from "./app/view/pages/setting/footer";
import SeoOptimization from "./app/view/pages/containtManagement/seoOptimization";
import AddSeoOptimization from "./app/view/pages/containtManagement/seoOptimization/form";
import Catering from "./app/view/pages/containtManagement/catering";
import { FOOTER_URL_ORDERS,DASHBOARD_URL, LOGIN_URL, PRODUCT_MANAGEMENT_MAIN_URL, CATAGORY_MANAGEMENT_URL, VARIANT_MANAGEMENT_URL, PRODUCT_MANAGEMENT_URL, PRODUCT_DETAIL_URL, PRODUCT_ADD_URL, CONTAINT_MANAGEMENT_URL, SEO_OPTIMIZATION_URL, EDIT_SEO_OPTIMIZATION_URL, ADD_SEO_OPTIMIZATION_URL, FEEDBACK_URL, CATERING_URL, CATERING_VIEW_URL, USER_MANAGEMENT_URL, SETTING_URL, ABOUT_US_URL, INFO_PAGES_URL, ADD_INFO_PAGES_URL, UPDATE_INFO_PAGES_URL, PRODUCT_EDIT_URL, KIT_MANAGEMENT_URL, RETAIL_STORE_URL, SUBSCRIPTION_MAIL_URL, FOOTER_URL, PRODUCT_SUBSCRIPTION_URL, USER_DETAIL_URL, COMMUNITY_EVENT_URL,  ORDER_MANAGEMENT_URL, ORDER_MANAGEMENT_DETAIL_URL, PAYMENT_MANAGEMENT_URL, COUPON_MANAGEMENT_URL, ADD_ABOUT_US_URL, ABOUT_US_DETAIL_URL, EDIT_ABOUT_US_URL, GIFT_CARD_URL, GIFT_CARDS_URL, USER_GIFT_CARD_URL, SUBSCRIPTION_ORDERS, INCOMPLETE_ORDERS } from "./app/constant/url";
import Alert from "./app/view/components/Alert";
import AboutUs from "./app/view/pages/containtManagement/aboutUs";
import InfoPages from "./app/view/pages/containtManagement/infoPages";
import AddPage from "./app/view/pages/containtManagement/infoPages/form";
import ViewProduct from "./app/view/pages/productManagement/product/view";
import AddProduct from "./app/view/pages/productManagement/product/form";
import PageLayout from "./app/view/components/PageLayout";
import Kit from "./app/view/pages/productManagement/kit";
import Loader from "./app/view/components/Loader";
import { usePosterReducers } from "./redux/getdata/usePostReducer";
import { useUiReducer } from "./redux/getdata/useUiReducer";
import RetailStore from "./app/view/pages/containtManagement/retailStore";
import Feedback from "./app/view/pages/containtManagement/feedback";
import SubscriptionMail from "./app/view/pages/containtManagement/subscriptionMail";
import Footer from "./app/view/pages/setting/footer";
import ProductSubscription from "./app/view/pages/productManagement/productSubscription";
import ViewUser from "./app/view/pages/userManagement/view";
import UserManagement from "./app/view/pages/userManagement";
import CommunityEvent from "./app/view/pages/containtManagement/communityEvent";
// import Review from "./app/view/pages/containtManagement/review";
import ViewCatering from "./app/view/pages/containtManagement/catering/view";
import OrderManagement from "./app/view/pages/orderManagement";
import ViewOrder from "./app/view/pages/orderManagement/view";
import PaymentManagement from "./app/view/pages/paymentManagement";
import CouponManagent from "./app/view/pages/couponManagement";
import AddAboutUs from "./app/view/pages/containtManagement/aboutUs/form";
import ViewStory from "./app/view/pages/containtManagement/aboutUs/view";
import UserGiftCard from "./app/view/pages/giftCards/userGiftCard";
import GiftCard from "./app/view/pages/giftCards/giftCard";
import FooterOrders from "./app/view/pages/setting/orderDate";
import SubscriptionManagement from "./app/view/pages/subscriptionManagement";
import IncompleteOrderManagement from "./app/view/pages/incompleteorders/Index";

function App() {
  const { is_login } = usePosterReducers()
  const { show_loader } = useUiReducer()

 
  const pathname = useLocation();



  return (
    <div className="bg-secondary  fixed w-[100%]" >
     <Loader show={show_loader}/>
     <Alert/>
      <div className={`${!is_login ? "" : "flex items-center"} `} >
        {is_login !== "no" && <Sidebar />}
        <div className={is_login !== "no" ? "w-[100%] sidebar_content_wrapper" : "w-[100%]"} >
          {is_login !== "no" && <Header />}
          <div
            className={`${
              pathname.pathname === "/" || pathname.pathname === "/login"
                ? "main-container"
                : "main-container-app m-2"
            }  rounded-md  "`}
          >
            <PageLayout className={FOOTER_URL_ORDERS ? true : false}>
              <Routes>
                <Route path="/"   element={ is_login === "no" ? (<Login />) : (<Navigate replace to={DASHBOARD_URL} />)}>
                  <Route path={LOGIN_URL} element={ is_login === "no" ? (<Login />) : (<Navigate replace to={DASHBOARD_URL} />)}/>
                </Route>
                
                <Route path={DASHBOARD_URL} element={is_login === "yes" ? ( <Dashboard />) : (<Navigate to={LOGIN_URL} />)}
                />
                <Route path={PRODUCT_MANAGEMENT_MAIN_URL}>
                  <Route path={CATAGORY_MANAGEMENT_URL}  element={is_login === "yes" ? ( <Category /> ) : (<Navigate replace to={LOGIN_URL} /> )}/>
                  <Route path={VARIANT_MANAGEMENT_URL}  element={ is_login === "yes" ? ( <Variant /> ) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={PRODUCT_MANAGEMENT_URL}  element={ is_login === "yes" ? ( <Product /> ) : ( <Navigate replace to={LOGIN_URL} /> )}/>

                  <Route path={PRODUCT_DETAIL_URL}  element={ is_login === "yes" ? ( <ViewProduct />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={PRODUCT_ADD_URL} element={is_login === "yes" ? (<AddProduct  />) : ( <Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={PRODUCT_EDIT_URL} element={is_login === "yes" ? (<AddProduct />) : ( <Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={KIT_MANAGEMENT_URL} element={is_login === "yes" ? (<Kit />) : ( <Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={PRODUCT_SUBSCRIPTION_URL} element={is_login === "yes" ? (<ProductSubscription />) : ( <Navigate replace to={LOGIN_URL} />)}/>
                </Route>
                <Route path={CONTAINT_MANAGEMENT_URL} >
                  <Route path={SEO_OPTIMIZATION_URL} element={ is_login === "yes" ? ( <SeoOptimization />) : (<Navigate replace to={LOGIN_URL} />)}/>

                  <Route path={ADD_SEO_OPTIMIZATION_URL} element={ is_login === "yes" ? (// @ts-ignore
                   <AddSeoOptimization />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={EDIT_SEO_OPTIMIZATION_URL} element={ is_login === "yes" ? (// @ts-ignore
                   <AddSeoOptimization />) : (<Navigate replace to={LOGIN_URL} />)}/>

                  
                  {/* <Route path={FUND_RAISING_URL} element={ is_login === "yes" ? ( <FundRaising />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={FUND_RAISING_DETAIL_URL} element={ is_login === "yes" ? ( <ViewFundRaise />) : (<Navigate replace to={LOGIN_URL} />)}/> */}
                  <Route path={FEEDBACK_URL} element={ is_login === "yes" ? ( <Feedback />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={CATERING_URL} element={ is_login === "yes" ? ( <Catering />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={ABOUT_US_URL} element={ is_login === "yes" ? ( <AboutUs />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={ADD_ABOUT_US_URL} element={ is_login === "yes" ? ( <AddAboutUs />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={EDIT_ABOUT_US_URL} element={ is_login === "yes" ? ( <AddAboutUs />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={ABOUT_US_DETAIL_URL} element={ is_login === "yes" ? ( <ViewStory />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={INFO_PAGES_URL} element={ is_login === "yes" ? ( <InfoPages />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={ADD_INFO_PAGES_URL} element={ is_login === "yes" ? ( <AddPage />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={UPDATE_INFO_PAGES_URL} element={ is_login === "yes" ? ( <AddPage />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={RETAIL_STORE_URL} element={ is_login === "yes" ? ( <RetailStore />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={SUBSCRIPTION_MAIL_URL} element={ is_login === "yes" ? ( <SubscriptionMail />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={COMMUNITY_EVENT_URL} element={ is_login === "yes" ? ( <CommunityEvent />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  {/* <Route path={REVIEW_URL} element={ is_login === "yes" ? ( <Review />) : (<Navigate replace to={LOGIN_URL} />)}/> */}
                  <Route path={CATERING_VIEW_URL} element={ is_login === "yes" ? ( <ViewCatering />) : (<Navigate replace to={LOGIN_URL} />)}/>
                </Route>
                <Route path={GIFT_CARD_URL}>
                  <Route path={GIFT_CARDS_URL} element={ is_login === "yes" ? ( <GiftCard />) : (<Navigate replace to={LOGIN_URL} />)}/>
                  <Route path={USER_GIFT_CARD_URL} element={ is_login === "yes" ? ( <UserGiftCard />) : (<Navigate replace to={LOGIN_URL} />)}/>
                </Route>
                <Route path={SETTING_URL}>
                <Route path={FOOTER_URL} element={ is_login === "yes" ? ( <Footer />) : (<Navigate replace to={LOGIN_URL} />)}/>
                <Route path={FOOTER_URL_ORDERS} element={ is_login === "yes" ? ( <FooterOrders />) : (<Navigate replace to={LOGIN_URL} />)}/>

                </Route>
                <Route path={USER_MANAGEMENT_URL} element={ is_login === "yes" ? ( <UserManagement /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={ORDER_MANAGEMENT_URL} element={ is_login === "yes" ? ( <OrderManagement /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={SUBSCRIPTION_ORDERS} element={ is_login === "yes" ? ( <SubscriptionManagement /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={INCOMPLETE_ORDERS} element={ is_login === "yes" ? ( <IncompleteOrderManagement /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={ORDER_MANAGEMENT_DETAIL_URL} element={ is_login === "yes" ? ( <ViewOrder /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={USER_DETAIL_URL} element={ is_login === "yes" ? ( <ViewUser /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={PAYMENT_MANAGEMENT_URL} element={ is_login === "yes" ? ( <PaymentManagement /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route path={COUPON_MANAGEMENT_URL} element={ is_login === "yes" ? ( <CouponManagent /> ) : ( <Navigate replace to={LOGIN_URL} />)}/>
                <Route  path={SETTING_URL} element={ is_login === "yes" ? ( <Setting />) : (<Navigate replace to={LOGIN_URL} />)}/>
                
              </Routes>
            </PageLayout>
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default App;
