/* eslint-disable */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FormLayout from "../../../components/FormLayout";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { IFooterOrderSetting,IFooterOrderSetting2 } from "../../../../utils/types";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import DatePicker from "react-datepicker";
import { RxCross2 } from "react-icons/rx";

interface IAppProps {
  setLoading: Dispatch<SetStateAction<boolean>>; 
  loading:boolean
}

type DateRange = {
  from: Date | null;
  to: Date | null;
};

const Form : React.FunctionComponent<IAppProps> = ({setLoading,loading}) => {
  const { register, getValues,setValue,handleSubmit, reset, control, watch, formState: { errors } } = useForm<IFooterOrderSetting>({
    defaultValues: {
     data:{
       excluded_days: [
          { id:"",from: null, to: null }
        ]
     } 
    }
  });
  
  const { fields: exclude, append, remove, } = useFieldArray({
    control,
    name: "data.excluded_days"
  });
  


  const { send, receivedMessage } = useWebSocket();
  const { shipping_dates } = usePosterReducers();
  const [dateRanges, setDateRanges] = useState([{ from: null, to: null }]); 
  // const [dateRanges, setDateRanges] = useState<DateRange[]>([]);



  
  const [startDate, setStartDate] = useState<Date | null>(null);
  // const toDate = watch('data.excluded_days.to');
  // const fromDate = watch('data.excluded_days.from');

  
  // Populate initial values on receiving data
  // useEffect(() => {
  //   const fromDate = shipping_dates?.data?.excluded_days?.from;
  //   const toDate = shipping_dates?.data?.excluded_days?.to;

  //   const validFromDate = fromDate ? new Date(fromDate) : null;
  //   const validToDate = toDate ? new Date(toDate) : null;
  //   if(!loading){
  //     reset({
  //       data: {
  //         ...shipping_dates,
  //         excluded_days: {
  //           ...shipping_dates?.data,
  //           from: validFromDate,
  //           to: validToDate,
  //         },
  //       }
  //     });
  //   }
  // }, [receivedMessage]);
  useEffect(() => {
    if (!loading) {
      let updatedDateRanges:any = [];

      if (Array.isArray(shipping_dates?.data?.excluded_days)) {
           updatedDateRanges = shipping_dates?.data?.excluded_days?.map(item => {
          const validFromDate = item?.from ? new Date(item?.from) : null;
          const validToDate = item?.to ? new Date(item?.to) : null;
          return {
            ...item,
            from: validFromDate,
            to: validToDate
          };
        });
      }

      reset({
        data: {
          ...shipping_dates,
          excluded_days:updatedDateRanges
        }
      });
    }
  }, [receivedMessage]);
  

  const onSubmitExcludedDates = (data: IFooterOrderSetting) => {
 const payload = {
      transmit: "single",
      url: "setting_order_date_update",
      request: {
       data: {
        // excluded_days: {
        //   from: data?.data?.excluded_days?.from
        //     ?.toString()
        //     .replace(/^[A-Za-z]+\s/, "")
        //     .split(" GMT")[0],
        //   to: data?.data?.excluded_days?.to
        //     ?.toString()
        //     .replace(/^[A-Za-z]+\s/, "")
        //     .split(" GMT")[0],
        // },
        excluded_days :data?.data?.excluded_days?.map((item:any) => ({
          from: item?.from
            ? new Date(item?.from).toLocaleString('en-US', { timeZoneName: 'short' })
            : "",
          to: item?.to
            ? new Date(item?.to).toLocaleString('en-US', { timeZoneName: 'short' })
            : "",
        })),
        orders_per_day: shipping_dates?.data?.orders_per_day
      },
        }
      }
      send(payload);
      setLoading(true)
      console.log("payload in date form",payload)

    }  
 
  const filterAllowedDays = (date: Date) => {
    const day = date.getDay();
    return day >= 2 && day <= 5;
  };

  // function enableWeekdaysOnly(fromDate: Date | null) {
  //   return (date: Date): boolean => {
  //     if (fromDate) {
  //       const day = date.getDay();  
  //       const currentDate = date.toISOString().split("T")[0]; 
  //       const fromDateString = fromDate.toISOString().split("T")[0]; 
  //       if (currentDate >= fromDateString && day >= 2 && day <= 5) {
  //         return true; 
  //       }
  //     }
  //     return false; 
  //   };
  // }
  
  
  const calculateMinDeliveryDate = (): Date | null => {
    const selectedDate = new Date();
    const minDeliveryDate = new Date(selectedDate);
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
    while (minDeliveryDate.getDay() < 2 || minDeliveryDate.getDay() > 5) {
      minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
    }
    return minDeliveryDate;
  };
  const minDeliveryDate = calculateMinDeliveryDate();

  const isDateDisabled = (date: Date, exclude: { from: Date | null; to: Date | null }[]) => {
    return exclude.some((range) => {
      const startDate = range.from ? new Date(range.from) : null;
      const endDate = range.to ? new Date(range.to) : null;  
      if (startDate && endDate) {
        return date >= startDate && date <= endDate;
      }
      return false;
    });
  };
  
  const filterAllowedDates = (index: number) => (date: Date) => {
    return (
      !isDateDisabled(
        date,
        exclude.filter((_, i) => i !== index) 
      ) &&
      date.getDay() >= 2 && // Tuesday
      date.getDay() <= 5 // Friday
    );
  };
  

  
  const addDateRange = () => {
    // const lastPair = exclude[exclude.length - 1]; 
    // const newStartDate = lastPair?.to ?? null; 
    const randomId = Math.random().toString(36).substr(2, 9);
    append({ id: randomId, from: null, to: null }); 
  
  };
  
  const removeDateRange = (id: string) => {
    const updatedExclude = exclude.filter((field) => field.id !== id);

    setValue("data.excluded_days", updatedExclude);    
    reset({ ...getValues(), data: { excluded_days: updatedExclude } }); 

  };

  const filterAllowedDates2 = (index: number) => (date: Date) => {
    if (startDate) {
      const minAllowedDate = new Date(startDate);
      minAllowedDate.setDate(minAllowedDate.getDate() + 1);
  
      return (
        date >= minAllowedDate && 
        (date.getDay() >= 2 && date.getDay() <= 5) 
      );
    }  
    return date.getDay() >= 2 && date.getDay() <= 5;
  };
  
  

  return (
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmitExcludedDates}
        containt={{ title: "Service Not Available Dates", submit: "Save" }}
        isHeight={true}
      >
        <div className="lg:flex w-[100%] gap-2">
          <div className="my-2 w-full">
            {/* <div className="flex w-full gap-3 mt-[20px]">
              <div className="w-[100%]">
                <label htmlFor="date" className="block font-medium text-gray-600 text-sm mb-[1px]">
                  Exclude Start Date <span className="text-red-500"> *</span>
                </label>
                <Controller
                  name="data.excluded_days.from"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Start date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Start Date"
                      onChange={(date) => {
                        field.onChange(date);
                        setStartDate(date);
                      }}
                      selected={field.value}
                      filterDate={filterAllowedDays}
                      minDate={minDeliveryDate}
                      dateFormat="yyyy-MM-dd"
                      className="lg:w-80 px-3 text-sm py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  )}
                />
                {errors?.data?.excluded_days?.from?.message && (
                  <p className="text-xs mx-1 text-red-500">{errors.data.excluded_days.from.message.toString()}</p>
                )}
              </div>

              <div className="w-[100%]">
                <label htmlFor="date" className="block font-medium text-gray-600 text-sm mb-[1px]">
                  Exclude End Date <span className="text-red-500"> *</span>
                </label>
                <Controller
                  name="data.excluded_days.to"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "End date is required" }}
                  render={({ field }) => (
                      <DatePicker
                      placeholderText="End Date"  
                      onChange={(date) => {
                          field?.onChange(date)
                          setStartDate(date)
                      }}
                      endDate={fromDate}            
                      selected={field?.value}
                      filterDate={filterAllowedDays}
                      minDate={startDate}
                      startDate={toDate}
                      showMonthDropdown
                      showYearDropdown  
                      dateFormat="yyyy-MM-dd"
                      className="lg:w-80 px-3 text-sm py-2 border border-gray-300 rounded-md  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
)}
                />
                {errors?.data?.excluded_days?.to?.message && (
                  <p className="text-xs mx-1 text-red-500">{errors.data.excluded_days.to.message.toString()}</p>
                )}
              </div>
            </div> */}

          {exclude?.map((range, index:number) => (
            <div className="flex w-full gap-3 mt-[20px]" key={index}>
              <div className="relative w-[100%]">
                <label htmlFor={`from-${index}`} className="block font-medium text-gray-600 text-sm mb-[1px]">
                Exclude Start Date <span className="text-red-500"> *</span>
                </label>
                <Controller
                  name={`data.excluded_days.${index}.from`} 
                  control={control}
                  defaultValue={range.from || null}
                  rules={{ required: "Start date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Start Date"
                      onChange={(date: any) => {
                        setValue(`data.excluded_days.${index}.from`, date); 
                        const updatedExclude = exclude?.map((item) =>
                          item?.id === range?.id ? { ...item, from: date } : item
                        );
                        setValue("data.excluded_days", updatedExclude);    
                        field.onChange(date); 
                        setStartDate(date);         
                      }}
                      selected={field.value}
                      // filterDate={index===0 ? filterAllowedDays :filterAllowedDays}
                      filterDate={filterAllowedDates(index)}
                      minDate={minDeliveryDate}
                      dateFormat="yyyy-MM-dd"
                      className="lg:w-80 px-3 text-sm py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  )}
                />
                     {errors?.data?.excluded_days?.[index]?.from && (
                    <p className="text-xs mx-1 text-red-500">
                       {errors?.data?.excluded_days?.[index]?.from?.message}
                    </p>
                      )}
              </div>

              <div className="relative w-[100%]">
                <div className="flex justify-start gap-[13rem]">
                <label htmlFor={`to-${index}`} className="block font-medium text-gray-600 text-sm mb-[1px]">
                Exclude End Date <span className="text-red-500"> *</span>
                </label>
                {
                  index ===0?  null:
                  <button
                type="button"
                className="h-fit w-fit text-sm font-medium border border-red-600 rounded-full text-red-600"

                onClick={() => removeDateRange(range?.id)}>
                <RxCross2/>
              </button>
                }
                </div>
                <Controller
                  name={`data.excluded_days.${index}.to`} 
                  control={control}
                  defaultValue={range.to || null}
                  rules={{ required: "End date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="End Date"
                      onChange={(date: any) => {
                        setValue(`data.excluded_days.${index}.to`, date); 
                        const updatedExclude = exclude?.map((item) =>
                          item?.id === range?.id ? { ...item, to: date } : item
                        );
                        setValue("data.excluded_days", updatedExclude);                    
                        field.onChange(date);     
                        setStartDate(null)     

                      }}
                      selected={field.value}
                      // filterDate={filterAllowedDays}
                      // filterDate={filterAllowedDates(index)}    
                      filterDate={filterAllowedDates2(index)}   
                      // minDate={minDeliveryDate}
                      showMonthDropdown
                      showYearDropdown  
                      dateFormat="yyyy-MM-dd"
                      className="lg:w-80 px-3 text-sm py-2 border border-gray-300 rounded-md  focus:outline-none focus:ring-blue-500 focus:border-blue-500"

                    />
                  )}
                />
                 {errors?.data?.excluded_days?.[index]?.to && (
                    <p className="text-xs mx-1 text-red-500">
                       {errors?.data?.excluded_days?.[index]?.to?.message}
                    </p>
                      )}
              </div>
            </div>
          ))}
          <button
            type="button"
            className="px-3 py-2 mt-2 text-sm font-medium text-white bg-blue-600 rounded"
            onClick={addDateRange}>
            + Add Dates
          </button>
          </div>
        </div>
      </FormLayout>
  );
};

export default Form;

