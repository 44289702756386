import React from "react";
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import ListComp from "../../components/ListComp";
import { dateFormat } from "../../../utils/common";
import {  payment_lists } from "../../../api/websocket/payload";
import Notfound from "../../components/Notfound";
interface IList {}

const List: React.FunctionComponent<IList> = (props) => {
  const { payment_list } = usePosterReducers();
  const header = [
    "transaction_id",
    'payment type',
    'name',
    'email',
    "amount ($)",
    "Created At",
    "payment status",
  ];

  return (
    <ListComp
      payLoad={payment_lists}
      pages={payment_list?.pagination}
      header={header}
    >
      {payment_list?.data?.length  > 0 ? 
        payment_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            <td className="py-3">{item.transaction_id}</td>
            <td className="py-3">{item.payment_type??'-'}</td>
            <td className="py-3">{item.order_details?.username??'-'}</td>
            <td className="py-3">{item.order_details?.email??'-'}</td>
            <td className="py-3">{item.amount&&(Number(item.amount)/100).toFixed(2)}</td>
            <td>{dateFormat(item.created_at)}</td>
            <td className="px-2  text-sm py-1">
            <span
                  className={
                    item.is_paid
                      ? "bg-green-100 text-green-600 px-2 py-1 rounded"
                      : "bg-red-100 text-red-600 px-2 py-1 rounded"
                  }
                >
                  {item.is_paid ? "Paid" : "Unpaid"}
                </span>
              {/* {item.is_paid?'Paid':'Unpaid'} */}
            </td>

            

            
          </tr>
        )):
         <Notfound msg="Payment Not Found!"/>
      }
    </ListComp>
  );
};


export default List;


