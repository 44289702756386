import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";

import React from "react";
import ListComp from "../../../components/ListComp";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { about_us_lists } from "../../../../api/websocket/payload";
import Notfound from "../../../components/Notfound";

interface IList {
  edit: Function;
  toggleWarn: Function;
  onEdit: Function;
}

const List: React.FunctionComponent<IList> = (props) => {
  const { about_us_list } = usePosterReducers();

  const navigate=useNavigate()

  const header = [
    // "Images",
    "Title",
    // "Description",
    "Created At",
    "Active",
    "Actions",
  ];

  return (
    <ListComp
      pages={about_us_list?.pagination}
      payLoad={about_us_lists}
      header={header}
    >
      {about_us_list?.data?.length >0 ?
        about_us_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            {/* <td className="p-3 grid grid-cols-2">
              {item.images.split(",")?.map((item, index) => (
                <img className="h-20 w-16 object-contain " src={item} alt="" />
              ))}
            </td> */}
            <td>{item.title}</td>
            {/* <td>
              <textarea
                id="product-name"
                className="rounded focus:outline-none text-start w-52 h-36  text-sm resize-none"
                value={item.description} // Join items with newline for vertical display
                readOnly
              />
            </td> */}
           
            <td>{dateFormat(item.created_at)}</td>

            <td className="px-4  text-sm py-2">
              <label className="inline-flex items-center cursor-pointer ">
                <input
                  type="checkbox"
                  className="sr-only peer "
                  onChange={()=>{}}
                  checked={item.is_active}
                  onClick={(e) => props.toggleWarn(e, item, "edit")}
                />
                <div className=" relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </td>
            <td>
              <div className="flex justify-center items-center gap-2">
              <button
                  onClick={() =>
                    navigate("/content-management/about-us/"+item.id)
                  }
                  className="p-2   text-black rounded-md"
                >
                  <IoEyeOutline />
                </button>
                <button
                  onClick={() => navigate('/content-management/about-us/edit-aboutus/'+item.id)}
                  className="p-2 text-base text-black rounded-md"
                >
                  <CiEdit />
                </button>
                <button
                  onClick={() => props.toggleWarn("", item.id, "delete")}
                  className="p-2  text-base text-black rounded-md"
                >
                  <AiOutlineDelete />
                </button>
              </div>
            </td>
          </tr>
        )):
        <Notfound msg="About Us Not Found!"/>
        }
    </ListComp>
  );
};

export default List;
