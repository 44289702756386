import React from "react";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import ListComp from "../../../components/ListComp";
import { user_giftcard_listing } from "../../../../api/websocket/payload";
import { dateFormat } from "../../../../utils/common";
import Notfound from "../../../components/Notfound";

interface IList {}

const List: React.FunctionComponent<IList> = (props) => {
  const { usergift_card_list } = usePosterReducers();
  const header = [
    "sender email",
    'recipient email',
    'code',
    'available amount ($)',
    "total amount ($)",
    "Created At",
  ];

  return (
    <ListComp
      payLoad={user_giftcard_listing}
      pages={usergift_card_list?.pagination}
      header={header}
    >
      {usergift_card_list?.data?.length > 0 ?
        usergift_card_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            <td className="py-3">{item.from_email}</td>
            <td className="py-3">{item.recipient_email??'-'}</td>
            <td className="py-3">{item.code??'-'}</td>
            <td className="py-3">{item.available_amount?(Number(item.available_amount)/100).toFixed(2):'-'}</td>
            <td className="py-3">{item.total_amount?(Number(item.total_amount)/100).toFixed(2):'-'}</td>
            <td>{dateFormat(item.created_at)}</td> 
          </tr>
        )):
        <Notfound msg="User Gift Card Not Found!"/>
        }
    </ListComp>
  );
};

export default List;
